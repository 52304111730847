import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"6","lg":"10","md":"8","sm":"6"}},[_c('h3',[_vm._v("Menu")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.getRegisters.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Quick Search","prepend-inner-icon":"mdi mdi-magnify","single-line":"","outlined":"","dense":""},on:{"click:prepend-inner":_vm.getRegisters},model:{value:(_vm.filter.fastSearch),callback:function ($$v) {_vm.$set(_vm.filter, "fastSearch", $$v)},expression:"filter.fastSearch"}})],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.filteredSearch,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"no-data-text":"No Record Found"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.mdiIcon",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(item.mdiIcon))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ActionList',{attrs:{"id":item.id,"link":'menuForm',"showButtons":{
                            edit: true
                        }}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }